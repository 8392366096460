import ApiService from "./ApiService";

export async function apiCountAnalysisClaim(data) {
  return ApiService.fetchData({
    url: `claims/count_analysis`,
    method: "POST",
    data,
  });
}

export const getClaimAgingRecapitulation = (data) => {
  return ApiService.fetchData({
    url: "claims/aging",
    method: "POST",
    data,
  });
};

export const getListDataClaims = (data) => {
  return ApiService.fetchData({
    url: "claims/data",
    method: "post",
    data,
  });
};

export const getListDataClaimByPolis = (data) => {
  return ApiService.fetchData({
    url: "claims/by_polis",
    method: "post",
    data,
  });
};

export async function getDetailDataClaim(id) {
  return ApiService.fetchData({
    url: `/claims/detail/${id}`,
    method: "get",
  });
}

export const updateDataClaim = (data) => {
  return ApiService.fetchData({
    url: `/claims/update/${data.id}`,
    method: "put",
    data,
  });
};

export const getDocumentByClaimId = (id, data) => {
  return ApiService.fetchData({
    url: `/claims/list_documents/${id}`,
    method: "get",
  });
};

export const storeRequirementDocument = (data) => {
  return ApiService.fetchData({
    url: "/claims/store_documents",
    method: "post",
    data,
  });
};

export const storeNonRequirementDocument = (data) => {
  return ApiService.fetchData({
    url: "/claims/store_non_req_document",
    method: "post",
    data,
  });
};

export const updateRequirementDocument = (id, data) => {
  return ApiService.fetchData({
    url: `/claims/update_document/${id}`,
    method: "put",
    data,
  });
};

export const deleteClaimDocument = (data) => {
  return ApiService.fetchData({
    url: `/claims/delete_document/${data.id}`,
    method: "delete",
    data,
  });
};

export const updateBatchDataClaim = async (data) => {
  return ApiService.fetchData({
    url: `/claims/update_batch/${data.id}`,
    method: "put",
    data,
  });
};

export const checkPolisClaim = async (data) => {
  return ApiService.fetchData({
    url: `/claims/claim_staging/is_claim`,
    method: "post",
    data,
  });
};

export const getListDanaTalangan = async (data) => {
  return ApiService.fetchData({
    url: `/claims/talangan/list/${data}`,
    method: "GET",
  });
};

export const storeDanaTalangan = async (data) => {
  return ApiService.fetchData({
    url: `/claims/talangan/store`,
    method: "post",
    data,
  });
};

export const getDocumentNonRequirementById = async (id) => {
  return ApiService.fetchData({
    url: `/claims/get_document/non_requirement/${id}`,
    method: "GET",
  });
};

export const getDocumentRequirementById = async (id) => {
  return ApiService.fetchData({
    url: `/claims/get_document/is_requirement/${id}`,
    method: "GET",
  });
};

export const updateDocumentDataClaim = async (data) => {
  return ApiService.fetchData({
    url: `/claims/checklist_doc/${data.id}`,
    method: "put",
    data,
  });
};

export const deleteStatusClaim = async (id) => {
  return ApiService.fetchData({
    url: `/claims/delete/${id}`,
    method: "delete",
  });
};

export const storeLaporanKlaim = async (data) => {
  return ApiService.fetchData({
    url: `/claims/claim_staging/store`,
    method: "POST",
    data,
  });
};

export const storeLaporanKlaimBySistem = async (data) => {
  return ApiService.fetchData({
    url: `/claims/claim_staging/store-by-system`,
    method: "POST",
    data,
  });
};

export const storePenolakan = async (data) => {
  return ApiService.fetchData({
    url: `/claims/penolakan`,
    method: "post",
    data,
  });
};

export const getLogClaim = async (data) => {
  return ApiService.fetchData({
    url: `/claims/log/${data}`,
    method: "GET",
  });
};

export const getListPreFinance = async (data) => {
  return ApiService.fetchData({
    url: `/claims/pre-finance`,
    method: "post",
    data,
  });
};

export const getInsuranceByProjectId = (params) => {
  return ApiService.fetchData({
    url: "/configurations/insurance/filter",
    method: "GET",
    params,
  });
};

export const getInsuranceListByProjectId = (params) => {
  return ApiService.fetchData({
    url: "/configurations/insurance/list",
    method: "GET",
    params,
  });
};

export const getListCoreBranch = (params) => {
  return ApiService.fetchData({
    url: "/users/filter-pusat",
    method: "GET",
    params,
  });
};

export const getListCentralBranch = (params) => {
  return ApiService.fetchData({
    url: "/users/filter-wilayah",
    method: "GET",
    params,
  });
};

export const getListRegionalBranch = (params) => {
  return ApiService.fetchData({
    url: "/users/filter-cabang",
    method: "GET",
    params,
  });
};

export const getChat = (data) => {
  return ApiService.fetchData({
    url: `/claims/get_chat`,
    method: "POST",
    data,
  });
};

export const storeChat = (data) => {
  return ApiService.fetchData({
    url: `/claims/store_chat`,
    method: "POST",
    data,
  });
};

export const getFileChat = (data) => {
  return ApiService.fetchData({
    url: `/claims/get-file-chat`,
    method: "POST",
    data,
  });
};

export const getFileChatClaim = (data) => {
  return ApiService.fetchData({
    url: `/users/chat/file/${data}`,
    method: "GET",
  });
};

export const sendDokumenPersetujuanEmail = (uuid) => {
  return ApiService.fetchData({
    url: `/claims/claim_email/dokumen_persetujuan/${uuid}`,
    method: "POST",
  });
};

export const sendKlaimDibayarEmail = (uuid) => {
  return ApiService.fetchData({
    url: `/claims/claim_email/klaim_dibayar/${uuid}`,
    method: "POST",
  });
};

export const sendKlaimDitolakEmail = (uuid) => {
  return ApiService.fetchData({
    url: `/claims/claim_email/klaim_ditolak/${uuid}`,
    method: "POST",
  });
};

export const apiPemetaanKlaim = (data) => {
  return ApiService.fetchData({
    url: `/claims/dashboard/pemetaan-klaim`,
    method: "POST",
    data,
  });
};

export const getClaimNotes = (claimId, params) => {
  return ApiService.fetchData({
    url: `/claims/note/list/${claimId}`,
    method: "GET",
    params,
  });
};

export const storeClaimNotes = (data) => {
  return ApiService.fetchData({
    url: `/claims/note/store`,
    method: "POST",
    data,
  });
};

export const deleteClaimNotes = (id) => {
  return ApiService.fetchData({
    url: `/claims/note/delete/${id}`,
    method: "DELETE",
  });
};
