import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Table, Pagination, Select, Checkbox, useConfig } from "components/ui";
import TableRowSkeleton from "./loaders/TableRowSkeleton";
import Loading from "./Loading";
import {
  useTable,
  useExpanded,
  usePagination,
  useSortBy,
  useRowSelect,
} from "react-table";
import classNames from "classnames";

const { Tr, Th, Td, THead, TBody, Sorter } = Table;

const IndeterminateCheckbox = forwardRef((props, ref) => {
  const {
    row,
    indeterminate,
    disabledCheckbox = noop,
    onChange,
    onCheckBoxChange,
    onIndeterminateCheckBoxChange,
    ...rest
  } = props;

  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  const handleChange = (e) => {
    onChange(e);
    onCheckBoxChange?.(e);
    onIndeterminateCheckBoxChange?.(e);
  };

  return (
    <Checkbox
      className="mb-0 mr-0"
      ref={resolvedRef}
      onChange={(_, e) => handleChange(e)}
      disabled={disabledCheckbox(row)}
      {...rest}
    />
  );
});

const DataTable = forwardRef((props, ref) => {
  const {
    rowKeyId = "",
    columns,
    data,
    loading,
    skeletonAvatarColumns,
    renderRowSubComponent,
    onCheckBoxChange,
    onIndeterminateCheckBoxChange,
    onPaginationChange,
    onPageSizeChange = noop,
    onSelectChange = noop,
    onSort,
    pageSizes,
    selectable,
    skeletonAvatarProps,
    pagingData,
    isPaging,
    messageNoData,
    enableFreezeColumn,
    disabledCheckbox = noop,
  } = props;

  const { pageSize, pageIndex, total } = pagingData;
  const { tableColor, tableColorLevel } = useConfig();

  const pageSizeOption = useMemo(
    () =>
      pageSizes.map((number) => ({
        value: number,
        label: `${number} / halaman`,
      })),
    [pageSizes]
  );

  const handleCheckBoxChange = (checked, row) => {
    onCheckBoxChange?.(checked, row);
  };

  const handleIndeterminateCheckBoxChange = (checked, rows) => {
    onIndeterminateCheckBoxChange?.(checked, rows);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    visibleColumns,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      autoResetSelectedRows: false,
      autoResetSelectedCell: false,
      autoResetSelectedColumn: false,
      ...(rowKeyId ? { getRowId: (row) => row[rowKeyId] } : {}),
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (selectable) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            className: "spacing-sm",
            width: 40,
            style: {
              width: 5,
            },
            pinned: enableFreezeColumn ? "left" : "",
            Header: (props) => (
              <div>
                <IndeterminateCheckbox
                  {...props.getToggleAllRowsSelectedProps()}
                  onIndeterminateCheckBoxChange={(e) =>
                    handleIndeterminateCheckBoxChange(
                      e.target.checked,
                      props.rows
                    )
                  }
                />
              </div>
            ),
            Cell: ({ row }) => (
              <div className="px-0">
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps()}
                  onCheckBoxChange={(e) =>
                    handleCheckBoxChange(e.target.checked, row.original)
                  }
                  row={row}
                  disabledCheckbox={disabledCheckbox}
                />
              </div>
            ),
            sortable: false,
          },
          ...columns,
        ]);
      }
    }
  );

  useImperativeHandle(
    ref,
    () => ({
      resetSelectedRows: () => toggleAllRowsSelected(false),
    }), // you can just use ({toggleAllRowsSelected}) and in Parent call tableRef.current.toggleAllRowsSelected() with ?bool param
    []
  );

  const handlePaginationChange = (page) => {
    if (!loading) {
      onPaginationChange?.(page);
    }
  };

  const handleSelectChange = (value) => {
    if (!loading) {
      onPageSizeChange(Number(value));
      onSelectChange(Number(value));
    }
  };

  const handleSort = (column) => {
    if (!loading) {
      const { id, isSortedDesc, toggleSortBy, clearSortBy } = column;
      const sortOrder = isSortedDesc ? "desc" : "asc";
      toggleSortBy(!isSortedDesc);
      onSort?.({ order: sortOrder, key: id }, { id, clearSortBy });
    }
  };

  return (
    <Loading loading={loading && data.length !== 0} type="cover">
      <Table
        {...getTableProps()}
        {...(enableFreezeColumn && {
          style: { borderCollapse: "collapse", width: "100%" },
        })}
      >
        <THead>
          {headerGroups.map((headerGroup) => (
            <Tr
              className={`bg-${tableColor}-${tableColorLevel}`}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps()}
                  className={`${
                    column.columns && column.columns.length > 0
                      ? "th-group"
                      : ""
                  } ${classNames(
                    column.className,
                    column.classNameHeaderColor
                  )}`}
                  {...(enableFreezeColumn && {
                    style: {
                      position: column.pinned ? "sticky" : "relative",
                      left:
                        column.pinned === "left"
                          ? `${column.totalLeft}px`
                          : "auto",
                      right: column.pinned === "right" ? 0 : "auto",
                      backgroundColor: "inherit",
                      zIndex: column.pinned ? 1 : "auto",
                      width: column.pinned ? `${column.totalLeft}px` : "auto",
                    },
                  })}
                >
                  {column.render("Header") &&
                    (column.sortable ? (
                      <div
                        className="cursor-pointer text-black relative"
                        onClick={() => handleSort(column)}
                      >
                        {column.columns && column.columns.length > 0 && (
                          <div className="absolute bottom-[-20px] left-0 right-0 border-b border-[#3B6392]"></div>
                        )}
                        {column.render("Header")}
                        <span
                          className={classNames(
                            column.classNameHeader,
                            "text-black font-bold"
                          )}
                        >
                          <Sorter sort={column.isSortedDesc} />
                        </span>
                      </div>
                    ) : (
                      <div
                        className={classNames(
                          column.classNameHeader,
                          "text-black font-bold relative"
                        )}
                      >
                        {column.columns && column.columns.length > 0 && (
                          <div className="absolute bottom-[-20px] left-0 right-0 border-b border-[#3B6392]"></div>
                        )}
                        {column.render("Header")}
                      </div>
                    ))}
                  {enableFreezeColumn && column.pinned && (
                    <div
                      className="absolute top-0 right-0 bottom-0 w-2"
                      style={{
                        boxShadow: "5px 0px 4px 0px rgba(0, 0, 0, 0.05)",
                      }}
                    ></div>
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </THead>
        {loading && data.length === 0 ? (
          <TableRowSkeleton
            columns={selectable ? columns.length + 1 : columns.length}
            rows={pagingData.pageSize}
            avatarInColumns={skeletonAvatarColumns}
            avatarProps={skeletonAvatarProps}
          />
        ) : (
          <TBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              const rowProps = row.getRowProps();
              return (
                <React.Fragment key={rowProps.key}>
                  <Tr {...rowProps}>
                    {row.cells.map((cell, index) => {
                      return (
                        <Td
                          {...cell.getCellProps()}
                          {...(enableFreezeColumn && {
                            style: {
                              position: cell.column.pinned
                                ? "sticky"
                                : "relative",

                              left:
                                cell.column.pinned === "left"
                                  ? `${cell.column.totalLeft}px`
                                  : "auto",
                              right:
                                cell.column.pinned === "right" ? 0 : "auto",
                              backgroundColor: cell.column.pinned
                                ? "#fff"
                                : "inherit",
                              zIndex: cell.column.pinned ? 1 : "auto",
                              width: cell.column.pinned
                                ? `${cell.column.totalLeft}`
                                : "auto",
                            },
                          })}
                          className={cell.column.className}
                        >
                          <div
                            className={classNames(
                              cell.column.classNameBody,
                              "text-black"
                            )}
                          >
                            {cell.render("Cell")}
                          </div>
                          {enableFreezeColumn && cell.column.pinned && (
                            <div
                              className="absolute top-0 right-0 bottom-0 w-2"
                              style={{
                                boxShadow:
                                  "5px 0px 4px 0px rgba(0, 0, 0, 0.05)",
                              }}
                            ></div>
                          )}
                        </Td>
                      );
                    })}
                    {/* We could pass anything into this */}
                  </Tr>
                  {row.isExpanded ? (
                    <Tr>
                      <Td colSpan={columns.length}>
                        {renderRowSubComponent({
                          row,
                          rowProps,
                          visibleColumns,
                          loading,
                        })}
                      </Td>
                    </Tr>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              );
            })}
            {page.length === 0 && (
              <Tr>
                <Td className="text-center text-black" colSpan={columns.length}>
                  {messageNoData || "Tidak ada data"}
                </Td>
              </Tr>
            )}
          </TBody>
        )}
      </Table>
      {isPaging ? (
        <div className="flex flex-col md:flex-row items-center justify-between mt-4">
          <Pagination
            pageSize={pageSize}
            currentPage={pageIndex}
            total={total}
            onChange={handlePaginationChange}
          />
          <div
            className="flex-1 w-full flex flex-row items-center justify-end gap-4"
            style={{ minWidth: 130 }}
          >
            <p className="w-fit">
              Menampilkan {pageSize} dari {total} data
            </p>
            <Select
              className="w-36"
              size="sm"
              menuPlacement="top"
              isSearchable={false}
              value={pageSizeOption.filter(
                (option) => option.value === pageSize
              )}
              options={pageSizeOption}
              onChange={(option) => handleSelectChange(option.value)}
            />
          </div>
        </div>
      ) : (
        <hr />
      )}
    </Loading>
  );
});

DataTable.propTypes = {
  rowKeyId: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool,
  onCheckBoxChange: PropTypes.func,
  onIndeterminateCheckBoxChange: PropTypes.func,
  onPaginationChange: PropTypes.func,
  onSelectChange: PropTypes.func,
  onSort: PropTypes.func,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  selectable: PropTypes.bool,
  skeletonAvatarColumns: PropTypes.arrayOf(PropTypes.number),
  skeletonAvatarProps: PropTypes.object,
  pagingData: PropTypes.shape({
    total: PropTypes.number,
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
  }),
  enableFreezeColumn: PropTypes.bool,
};

DataTable.defaultProps = {
  rowKeyId: "",
  pageSizes: [10, 25, 50, 100],
  pagingData: {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
  },
  data: [],
  columns: [],
  selectable: false,
  loading: false,
  isPaging: true,
  enableFreezeColumn: false,
};

export default DataTable;
